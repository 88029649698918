import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {updateForm, addImage, removeImage} from './featureSlice';
import FileUpload from '../../../../components/file-upload/FileUpload';

export default function FeatureForm() {
  const form = useSelector(state => state.features.form);
  const loading = useSelector(state => state.features.loading.form);
  const mode = useSelector(state => state.features.dialog.mode);
  const product = useSelector(state => state.product.item);
  const locale = useSelector(state => state.app.locale);
  const defaultLocale = useSelector(state => state.app.defaultLocale);
  const dispatch = useDispatch();

  if (loading) {
    return <CircularProgress/>;
  }

  const featureOptions = product.features
    .slice()
    .filter(f => f['@id'] !== form['@id']) // Remove current feature from the list, so it can't be added as an incompatible/required feature
    .sort((f1, f2) => f1.name.localeCompare(f2.name))
    .map(f => (
    <MenuItem key={f['@id']} value={f['@id']}>
      {f.name}
    </MenuItem>
  ));
  const featureRenderValue = selected => selected.map(value => {
    const feature = product.features.find(f => f['@id'] === value);
    return (<Chip key={value['@id']} label={feature.name}/>)
  });

  const globalFields = (mode === 'add' || locale === defaultLocale) ? (
      <>
        <Grid item>
          <TextField
            value={form.url}
            onChange={e => dispatch(updateForm({prop: 'url', value: e.target.value}))}
            type="url"
            label="URL"
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            value={form.price}
            onChange={e => dispatch(updateForm({prop: 'price', value: e.target.value}))}
            type="number"
            label="Prix"
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item>
          <FileUpload
            files={form.images}
            multiple={true}
            addFile={file => dispatch(addImage(file))}
            removeFile={file => dispatch(removeImage(file))}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth margin="normal">
            <InputLabel id="incompatibleFeatures">Options incompatibles</InputLabel>
            <Select
              value={form.incompatibleFeatures}
              onChange={e => dispatch(updateForm({prop: 'incompatibleFeatures', value: e.target.value}))}
              labelId="incompatibleFeatures"
              multiple
              fullWidth
              renderValue={featureRenderValue}
            >
              {featureOptions}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth margin="normal">
            <InputLabel id="requiredFeatures">Options requises</InputLabel>
            <Select
              value={form.requiredFeatures}
              onChange={e => dispatch(updateForm({prop: 'requiredFeatures', value: e.target.value}))}
              labelId="requiredFeatures"
              multiple
              fullWidth
              renderValue={featureRenderValue}
            >
              {featureOptions}
            </Select>
          </FormControl>
        </Grid>
      </>
  ) : null;

  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          value={form.name}
          onChange={e => dispatch(updateForm({ prop: 'name', value: e.target.value }))}
          label="Nom"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          value={form.description}
          onChange={e => dispatch(updateForm({ prop: 'description', value: e.target.value }))}
          label="Description"
          margin="normal"
          multiline
          fullWidth
        />
      </Grid>
      {globalFields}
    </Grid>
  );
}
