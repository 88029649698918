import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../../../appSlice';
import ApiService from '../../../../app/services/api.service';
import {addFeature, editFeature, moveFeature as productMoveFeature, removeFeature} from '../productItemSlice';
import {closeModal} from '../../../alert/alertDialogSlice';

export const fetchFeature = createAsyncThunk(
  'features/fetchOne',
  async (id, {getState}) => await ApiService.get('features', id, {
    headers: {
      'X-LOCALE': getState().app.locale
    }
  })
);

export const saveFeature = createAsyncThunk(
  'features/save',
  async (arg, { getState, dispatch }) => {
    const form = getState().features.form;
    let feature;
    if (getState().features.dialog.mode === 'add') {
      const data = {
        ...form,
        product: getState().product.item['@id'],
        category: getState().product.selectedCategory,
        images: form.images.map(i => i['@id'])
      };
      feature = await ApiService.post('features', data, {}, {
        201: () => {
          dispatch(enqueueSnackbar({ message: 'Option ajoutée avec succès !', options: {variant: 'success'} }))
        }
      });
      dispatch(addFeature(feature));
    } else {
      const data = {
        ...form,
        category: form.category['@id'],
        images: form.images.map(i => i['@id'])
      };
      feature =  await ApiService.patch('features', getState().features.form.id, data, {
        headers: {
          'X-LOCALE': getState().app.locale
        }
      }, {
        200: () => dispatch(enqueueSnackbar({ message: 'Option modifiée avec succès !', options: {variant: 'success'} }))
      });
      if (getState().app.locale === getState().app.defaultLocale) {
        dispatch(editFeature(feature));
      }
    }
    return feature;
  }
);

export const moveFeature = createAsyncThunk(
  'features/move',
  async ({feature, position}, { getState, dispatch }) => {
    const previousPosition = feature.position;
    const newFeature = await ApiService.patch('features', feature.id, { position: position });
    dispatch(productMoveFeature({ feature: newFeature, previousPosition: previousPosition }))
    return { feature: newFeature, previousPosition: previousPosition };
  }
);

export const deleteFeature = createAsyncThunk(
  'features/remove',
  async (feature, { getState, dispatch }) => {
    await ApiService.delete('features', feature.id, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Option supprimée avec succès !', options: {variant: 'success'} }));
        dispatch(closeModal());
      }
    });
    dispatch(removeFeature(feature));
    return feature;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false
  },
  dialog: {
    mode: 'add',
    open: false
  },
  form: {
    name: '',
    description: '',
    price: '0',
    category: null,
    images: [],
    incompatibleFeatures: [],
    requiredFeatures: []
  }
};

export const featureSlice = createSlice({
  name: 'features',
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.form = action.payload;
    },
    updateForm: (state, action) => {
      state.form[action.payload.prop] = action.payload.value;
    },
    addImage: (state, action) => {
      state.form.images.push(action.payload);
    },
    removeImage: (state, action) => {
      state.form.images = state.form.images.filter(i => i['@id'] !== action.payload['@id']);
    },
    openDialog: (state, action) => {
      if (action.payload === 'edit') {
        state.dialog.mode = 'edit';
      } else {
        state.form = initialState.form;
        state.dialog.mode = 'add';
      }
      state.dialog.open = true;
    },
    closeDialog: (state, action) => {
      state.dialog.open = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeature.pending, state => {
        state.loading.form = true;
      })
      .addCase(fetchFeature.fulfilled, (state, action) => {
        state.form = {
          ...action.payload,
          incompatibleFeatures: action.payload.incompatibleFeatures ? action.payload.incompatibleFeatures.map(f => f['@id']) : [],
          requiredFeatures: action.payload.requiredFeatures ? action.payload.requiredFeatures.map(f => f['@id']) : [],
        };
        state.loading.form = false;
      })
      .addCase(fetchFeature.rejected, (state, error) => {
        state.loading.form = false;
      })
      .addCase(saveFeature.pending, state => {
        state.loading.form = true;
      })
      .addCase(saveFeature.fulfilled, (state) => {
        state.form = initialState.form;
        state.loading.form = false;
        state.dialog.open = false;
      })
      .addCase(saveFeature.rejected, (state) => {
        state.loading.form = false;
      })
      .addCase(moveFeature.rejected, (state, error) => {
        console.log(error)
      })
      .addCase(deleteFeature.pending, (state, action) => {
        state.loading.feature = action.meta.arg;
      })
      .addCase(deleteFeature.fulfilled, (state) => {
        state.dialog.open = false;
      })
      .addCase(deleteFeature.rejected, (state) => {
        state.loading.feature = null;
      });
  }
});

export const { setForm, updateForm, addImage, removeImage, openDialog, closeDialog } = featureSlice.actions;

export default featureSlice.reducer;
