import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Select from 'react-select';
import {fetchProductBySlug, saveProduct, updateProduct} from './productItemSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import ProductFeatures from './features/ProductFeatures';
import VersionList from './versions/list/VersionList';

export default function ProductItem() {
  const {slug} = useParams();
  const loading = useSelector(state => state.product.loading);
  const product = useSelector(state => state.product.item);
  const highlights = useSelector(state => state.highlights.items);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductBySlug(slug));
  }, [dispatch, slug]);

  if (loading.product || !product) {
    return <CircularProgress/>;
  }

  const productSubmit = e => {
    e.preventDefault();
    dispatch(saveProduct());
  };

  return (
    <>
      <form onSubmit={e => productSubmit(e)}>
        <TextField
          value={product.name}
          onChange={e => dispatch(updateProduct({prop: 'name', value: e.target.value}))}
          label="Nom"
          fullWidth
          margin="normal"
        />
        <TextField
          value={product.price}
          onChange={e => dispatch(updateProduct({prop: 'price', value: e.target.value}))}
          type="number"
          label="Prix"
          margin="normal"
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>
          }}
        />
        <TextField
          value={product.description}
          onChange={e => dispatch(updateProduct({prop: 'description', value: e.target.value}))}
          label="Description"
          margin="normal"
          fullWidth
          multiline
        />
        <Select
          options={highlights.map(h => ({ value: h['@id'], label: h.name }))}
          value={product.highlights.map(h => ({ value: h['@id'], label: h.name }))}
          onChange={selected => dispatch(updateProduct({prop: 'highlights', value: highlights.filter(h => selected.map(s => s.value).includes(h['@id']))}))}
          isMulti={true}
        />
        <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon/>}>
          Enregistrer
        </Button>
      </form>
      <hr/>
      <Typography variant="h6">
        Versions
      </Typography>
      <VersionList versions={product.versions}/>
      <hr/>
      <Grid container>
        <Grid item xl={8} lg={10} xs={12}>
          <Typography variant="h6">
            Options
          </Typography>
          <ProductFeatures/>
        </Grid>
      </Grid>
    </>
  );
}
