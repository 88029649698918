import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../appSlice';
import ApiService from '../../app/services/api.service';

export const fetchHighlights = createAsyncThunk(
  'highlights/fetchAll',
  async () => await ApiService.get('highlights')
);

export const fetchHighlight = createAsyncThunk(
  'highlights/fetchOne',
  async (id, { getState }) => await ApiService.get('highlights', id, {
    headers: {
      'X-LOCALE': getState().app.locale
    }
  })
);

export const saveHighlight = createAsyncThunk(
  'highlights/save',
  async (arg, { getState, dispatch }) => {
    if (getState().highlights.dialog.mode === 'add') {
      return await ApiService.post('highlights', { ...getState().highlights.form }, {}, {
        201: () => dispatch(enqueueSnackbar({ message: 'Point fort ajouté avec succès !', options: {variant: 'success'} }))
      });
    } else {
      const highlight = await ApiService.patch('highlights', getState().highlights.form.id, { ...getState().highlights.form }, {
        headers: {
          'X-LOCALE': getState().app.locale
        }
      }, {
        200: () => dispatch(enqueueSnackbar({ message: 'Point fort modifié avec succès !', options: {variant: 'success'} }))
      });
      return getState().app.locale === getState().app.defaultLocale ? highlight : null;
    }
  }
);

export const removeHighlight = createAsyncThunk(
  'highlights/remove',
  async (highlightId, { getState, dispatch }) => {
    await ApiService.delete('highlights', highlightId, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Point fort supprimé avec succès !', options: {variant: 'success'} }));
        dispatch(closeDialog());
      }
    });
    return highlightId;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false
  },
  dialog: {
    mode: 'add',
    open: false
  },
  form: {
    name: '',
    icon: ''
  },
  items: [],
  selectedHighlight: null
};

export const highlightSlice = createSlice({
  name: 'highlightList',
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.form = action.payload;
    },
    updateForm: (state, action) => {
      state.form[action.payload.prop] = action.payload.value;
    },
    setSelectedHighlight: (state, action) => {
      state.selectedHighlight = action.payload;
    },
    openDialog: (state, action) => {
      state.form = initialState.form;
      state.dialog.open = true;
      state.dialog.mode = action.payload ?? 'add';
    },
    closeDialog: (state, action) => {
      state.dialog.open = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHighlights.pending, (state) => {
      state.loading.list = true;
    });
    builder.addCase(fetchHighlights.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading.list = false;
    });
    builder.addCase(fetchHighlights.rejected, (state) => {
      state.loading.list = false;
    });
    builder.addCase(fetchHighlight.pending, (state) => {
      state.loading.form = true;
    });
    builder.addCase(fetchHighlight.fulfilled, (state, action) => {
      state.form = action.payload;
      state.loading.form = false;
    });
    builder.addCase(fetchHighlight.rejected, (state) => {
      state.loading.form = false;
    });
    builder.addCase(saveHighlight.pending, state => {
      state.loading.form = true;
    });
    builder.addCase(saveHighlight.fulfilled, (state, action) => {
      if (state.dialog.mode === 'add') {
        state.items.push(action.payload);
      } else if (action.payload !== null) {
        state.items = state.items.map(h => h['@id'] === state.form['@id'] ? action.payload : h);
      }
      state.form = initialState.form;
      state.loading.form = false;
      state.dialog.open = false;
    });
    builder.addCase(saveHighlight.rejected, (state) => {
      state.loading.form = false;
    });
    builder.addCase(removeHighlight.pending, (state, action) => {
      state.loading.highlight = action.meta.arg;
    });
    builder.addCase(removeHighlight.fulfilled, (state, action) => {
      state.items = state.items.filter(i => i.id !== action.payload);
      state.selectedHighlight = null;
      state.loading.highlight = null;
    });
    builder.addCase(removeHighlight.rejected, (state) => {
      state.loading.highlight = null;
    });
  },
});

export const { setForm, updateForm, setSelectedHighlight, openDialog, closeDialog } = highlightSlice.actions;

export default highlightSlice.reducer;
