import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import {openDialog} from '../versionSlice';
import VersionDialog from '../VersionDialog';
import VersionRow from './VersionRow';
import AddIcon from "@mui/icons-material/Add";

export default function VersionList(props) {
  const {versions} = props;
  const loading = useSelector(state => state.versions.loading.list);
  const dispatch = useDispatch();

  if (loading || !versions) {
    return <CircularProgress/>;
  }

  const rows = versions.slice().sort((v1, v2) => v1.position > v2.position).map(v => <VersionRow key={v.id} version={v}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog())} startIcon={<AddIcon/>} variant="contained" color="primary">
        Ajouter une version
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <VersionDialog/>
    </>
  );
}
