import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {enqueueSnackbar} from '../../../appSlice';
import ApiService from '../../../app/services/api.service';

export const fetchProductBySlug = createAsyncThunk(
  'products/fetchBySlug',
  async productSlug => await ApiService.get('products', productSlug)
);

export const saveProduct = createAsyncThunk(
  'products/save',
  async (data, { getState, dispatch }) => await ApiService.patch('products', getState().product.item.slug, {
    ...getState().product.item,
    versions: getState().product.item.versions.map(v => v['@id']),
    features: getState().product.item.features.map(f => f['@id']),
    highlights: getState().product.item.highlights.map(h => h['@id']),
  }, {}, {
    200: () => dispatch(enqueueSnackbar({ message: 'Produit mis à jour avec succès !', options: {variant: 'success'} }))
  })
);

export const openModal = createAsyncThunk(
  'modal/open',
  async (data, { getState, dispatch }) => {
    if (data.resource && data.slug) {
      const response = await ApiService.get(data.resource, data.slug, {
        headers: {
          'X-LOCALE': data.locale ?? getState().app.locale
        }
      });
      return {
        modal: data.modal,
        fields: response,
        mode: data.mode
      };
    } else {
      return { modal: data.modal, mode: data.mode };
    }
  }
);

const initialState = {
  loading: {
    product: false
  },
  item: null,
  selectedCategory: null
};

export const productItemSlice = createSlice({
  name: 'productItem',
  initialState: initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form[action.payload.form] = { ...state.form[action.payload.form], [action.payload.name]: action.payload.value };
    },
    setForm: (state, action) => {
      state.form[action.payload.form] = action.payload.fields;
    },
    addVersion: (state, action) => {
      state.item.versions.push(action.payload);
    },
    editVersion: (state, action) => {
      state.item.versions = state.item.versions.map(v => v.id === action.payload.id ? action.payload : v);
    },
    removeVersion: (state, action) => {
      state.item.versions = state.item.versions.filter(v => v.id !== action.payload.id);
    },
    addFeature: (state, action) => {
      state.item.features.push(action.payload);
    },
    editFeature: (state, action) => {
      state.item.features = state.item.features.map(f => f.id === action.payload.id ? action.payload : f);
    },
    moveFeature: (state, action) => {
      const {feature, previousPosition} = action.payload;
      state.item.features = state.item.features.map(f => {
        if (f.id === feature.id) {
          f.position = feature.position;
        } else if (f.category.id === feature.category.id && f.position === feature.position) {
          f.position = previousPosition;
        }
        return f;
      });
    },
    moveVersion: (state, action) => {
      const {version, previousPosition} = action.payload;
      state.item.versions = state.item.versions.map(v => {
        if (v.id === version.id) {
          v.position = version.position;
        } else if (v.position === version.position) {
          v.position = previousPosition;
        }
        return v;
      });
    },
    removeFeature: (state, action) => {
      state.item.features = state.item.features.filter(f => f.id !== action.payload.id);
    },
    closeModal: (state, action) => {
      state.modal[action.payload].open = false;
    },
    updateProduct: (state, action) => {
      state.item[action.payload.prop] = action.payload.value;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductBySlug.pending, (state) => {
      state.loading.product = true;
    });
    builder.addCase(fetchProductBySlug.fulfilled, (state, action) => {
      state.item = action.payload;
      state.loading.product = false;
    });
    builder.addCase(fetchProductBySlug.rejected, (state) => {
      state.loading.product = false;
    });
    builder.addCase(saveProduct.pending, (state) => {
      state.loading.product = true;
    });
    builder.addCase(saveProduct.fulfilled, (state, action) => {
      state.item = action.payload;
      state.loading.product = false;
    });
    builder.addCase(saveProduct.rejected, (state, action) => {
      state.loading.product = false;
    });
    builder.addCase(openModal.fulfilled, (state, action) => {
      if (typeof action.payload.fields !== 'undefined') {
        state.form[action.payload.modal] = action.payload.fields;
      }
      if (typeof action.payload.mode !== 'undefined') {
        state.formMode[action.payload.modal] = action.payload.mode;
      }
      state.modal[action.payload.modal].open = true;
    });
  }
});

export const { updateForm, setForm, addVersion, editVersion, moveVersion, removeVersion, addFeature, editFeature, moveFeature, removeFeature, closeModal, updateProduct, setSelectedCategory } = productItemSlice.actions;

export default productItemSlice.reducer;
