import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ApiService from '../../app/services/api.service';
import Preview from './Preview';
import './FileUpload.scss';

function FileUpload(props) {
  const { files, addFile, removeFile, multiple } = props;
  const [loading, setLoading] = useState(false);
  const upload = async newFiles => {
    setLoading(true);
    for (let i = 0; i < newFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', newFiles[i])
      const file = await ApiService.post('media_objects', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      addFile(file);
    }
    setLoading(false);
  };
  const filesJsx = files.map(f => <Preview key={f['@id']} file={f} remove={() => removeFile(f)}/>);

  if (loading) {
    filesJsx.push(<Preview key='preview' loading={true}/>)
  }

  return (
    <div className='file-upload'>
      <Button variant="contained" component="label">
        {multiple || files.length === 0 ? 'Ajouter une image' : 'Modifier'}
        <input
          onChange={e => upload(e.target.files)}
          type="file"
          multiple={multiple}
          hidden
        />
      </Button>
      <div className="files">
        {filesJsx}
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  files: PropTypes.array,
  addFile: PropTypes.func,
  removeFile: PropTypes.func,
  multiple: PropTypes.bool
};

export default FileUpload;
