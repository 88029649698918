import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TableRow from '@mui/material/TableRow';
import { removeHighlight, openDialog, fetchHighlight} from '../highlightSlice';
import EditBtnWithLocales from '../../../components/BtnEditWithLocales';
import ConfirmDialog from "../../../components/ConfirmDialog";

export default function HighlightRow(props) {
  const {highlight} = props;
  const loading = useSelector(state => state.highlights.loading);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const handleEditModal = e => {
    e.preventDefault();
    dispatch(fetchHighlight(highlight.id));
    dispatch(openDialog('edit'));
  }

  return (
    <TableRow key={highlight.id}>
      <TableCell scope="row">{highlight.name}</TableCell>
      <TableCell scope="row">
        <i className={highlight.icon}></i>
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <EditBtnWithLocales onClick={handleEditModal}/>
        <IconButton onClick={() => setConfirmOpen(true)} disabled={loading.highlight === highlight.id} color="error" aria-label="Supprimer">
          <DeleteIcon/>
        </IconButton>
        <ConfirmDialog
          title={'Supprimer ' + highlight.name + ' ?'}
          confirmLabel='Supprimer'
          confirmColor='secondary'
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={() => dispatch(removeHighlight(highlight.id))}
        >
          <p>Êtes-vous sur de vouloir supprimer {highlight.name} ?</p>
        </ConfirmDialog>
      </TableCell>
    </TableRow>
  );
};
