import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {updateForm} from './versionSlice';
import FileUpload from '../../../../components/file-upload/FileUpload';
import VersionFeatureList from './features/VersionFeatureList';

export default function VersionForm() {
  const form = useSelector(state => state.versions.form);
  const dispatch = useDispatch();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <TextField
              value={form.name}
              onChange={e => dispatch(updateForm({ prop: 'name', value: e.target.value }))}
              fullWidth
              label="Nom"
              margin="normal"
            />
          </Grid>
          <Grid item>
            <TextField
              value={form.description}
              onChange={e => dispatch(updateForm({ prop: 'description', value: e.target.value }))}
              label="Description"
              margin="normal"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              value={form.price}
              onChange={e => dispatch(updateForm({prop: 'price', value: e.target.value}))}
              type="number"
              label="Prix"
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
            />
          </Grid>
          <Grid>
            <FileUpload
              files={form.image !== null ? [form.image] : []}
              multiple={false}
              addFile={file => dispatch(updateForm({prop: 'image', value: file}))}
              removeFile={() => dispatch(updateForm({prop: 'image', value: null}))}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <h2>Options</h2>
        <VersionFeatureList/>
      </Grid>
    </Grid>
  );
}
