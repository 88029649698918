import React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {updateForm} from "./categorySlice";
import {useDispatch, useSelector} from "react-redux";

export default function CategoryForm() {
  const form = useSelector(state => state.categories.form);
  const dispatch = useDispatch();

  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          value={form.name}
          onChange={e => dispatch(updateForm({ prop: 'name', value: e.target.value }))}
          label="Nom"
          margin="normal"
        />
      </Grid>
    </Grid>
  );
}
