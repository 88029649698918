import React from 'react';
import {useDispatch} from 'react-redux';
import Button from '@mui/material/Button';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import ExtensionIcon from '@mui/icons-material/Extension';
import { openModal, closeModal } from '../../alert/alertDialogSlice';
import {deleteFeature, fetchFeature, moveFeature, openDialog} from '../../product/item/features/featureSlice';
import EditBtnWithLocales from '../../../components/BtnEditWithLocales';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import styles from './FeatureTreeItem.module.css';

export default function FeatureTreeItem(props) {
  const {feature} = props;
  const dispatch = useDispatch();

  const handleDeleteButton = () => {
    dispatch(openModal({
      title: `Supprimer ${feature.name} ?`,
      content: `Êtes-vous sûr de vouloir supprimer ${feature.name} ?`,
      buttons: [
        <Button key='delete' onClick={() => dispatch(deleteFeature(feature))} color="error">Supprimer</Button>,
        <Button key='cancel' onClick={() => dispatch(closeModal())} color="primary">Annuler</Button>
      ]
    }));
  };

  const handleEditModal = () => {
    dispatch(fetchFeature(feature.id));
    dispatch(openDialog('edit'));
  }

  const handleMove = (event, position) => {
    event.stopPropagation();
    dispatch(moveFeature({feature, position: feature.position + position}));
  }

  const label = (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <ExtensionIcon/>
        {feature.name} ({feature.position})
      </div>
      <div className={styles.buttonsContainer}>
        <IconButton onClick={e => handleMove(e, -1)} color="primary" aria-label="Monter">
          <ArrowUpward/>
        </IconButton>
        <IconButton onClick={e => handleMove(e, 1)} color="primary" aria-label="Descendre">
          <ArrowDownward/>
        </IconButton>
        <EditBtnWithLocales onClick={handleEditModal}/>
        <Button onClick={handleDeleteButton} className={styles.btnDelete} startIcon={<DeleteIcon/>} variant="contained" color="error">Supprimer</Button>
      </div>
    </div>
  );

  return (
    <TreeItem key={feature['@id']} itemId={feature['@id']} label={label}/>
  );
}
