import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../appSlice';
import ApiService from '../../app/services/api.service';

export const fetchUsers = createAsyncThunk(
  'users/fetchAll',
  async () => await ApiService.get('users')
);

export const fetchUser = createAsyncThunk(
  'users/fetchOne',
  async (id, { getState }) => await ApiService.get('users', id)
);

export const saveUser = createAsyncThunk(
  'users/save',
  async (arg, { getState, dispatch }) => {
    if (getState().users.dialog.mode === 'add') {
      return await ApiService.post('users', { ...getState().users.form }, {}, {
        201: () => dispatch(enqueueSnackbar({ message: 'Utilisateur ajouté avec succès !', options: {variant: 'success'} }))
      });
    } else {
      const user = await ApiService.patch('users', getState().users.form.id, { ...getState().users.form }, {}, {
        200: () => dispatch(enqueueSnackbar({ message: 'Utilisateur modifié avec succès !', options: {variant: 'success'} }))
      });
      return getState().app.locale === getState().app.defaultLocale ? user : null;
    }
  }
);

export const removeUser = createAsyncThunk(
  'users/remove',
  async (userId, { getState, dispatch }) => {
    await ApiService.delete('users', userId, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Utilisateur supprimé avec succès !', options: {variant: 'success'} }));
        dispatch(closeDialog());
      }
    });
    return userId;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false
  },
  dialog: {
    mode: 'add',
    open: false
  },
  form: {
    email: '',
    password: ''
  },
  errors: [],
  items: [],
  selectedUser: null
};

export const userSlice = createSlice({
  name: 'userList',
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.form = action.payload;
    },
    updateForm: (state, action) => {
      state.form[action.payload.prop] = action.payload.value;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    openDialog: (state, action) => {
      state.form = initialState.form;
      state.dialog.open = true;
      state.dialog.mode = action.payload ?? 'add';
    },
    closeDialog: (state, action) => {
      state.dialog.open = false;
    },
    dismissError: (state, action) => {
      state.errors = state.errors.filter((e, index) => index !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading.list = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading.list = false;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading.list = false;
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.loading.form = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.form = action.payload;
      state.loading.form = false;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.loading.form = false;
    });
    builder.addCase(saveUser.pending, (state) => {
      state.loading.form = true;
    });
    builder.addCase(saveUser.fulfilled, (state, action) => {
      if (state.dialog.mode === 'add') {
        state.items.push(action.payload);
      } else if (action.payload !== null) {
        state.items = state.items.map(u => {
          if (u['@id'] === state.form['@id']) {
            return action.payload;
          }
          return u;
        });
      }
      state.form = initialState.form;
      state.loading.form = false;
      state.dialog.open = false;
    });
    builder.addCase(saveUser.rejected, (state, error) => {
      console.log(error);
      state.errors.push({
        severity: "error",
        text: "Une erreur s'est produite lors de l'enregistrement des données."
      });
      state.loading.form = false;
    });
    builder.addCase(removeUser.pending, (state, action) => {
      state.loading.user = action.meta.arg;
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.items = state.items.filter(i => i.id !== action.payload);
      state.selectedUser = null;
      state.loading.user = null;
    });
    builder.addCase(removeUser.rejected, (state) => {
      state.loading.user = null;
    });
  },
});

export const { setForm, updateForm, setSelectedUser, openDialog, closeDialog, dismissError } = userSlice.actions;

export default userSlice.reducer;
