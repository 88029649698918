import React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {updateForm} from "./highlightSlice";
import FontAwesomeSelector, {CLASS_TYPE} from "../../components/FontAwesomeSelector";
import {useDispatch, useSelector} from "react-redux";

export default function HighlightForm() {
  const form = useSelector(state => state.highlights.form);
  const dispatch = useDispatch();

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item>
        <TextField
          value={form.name}
          onChange={e => dispatch(updateForm({ prop: 'name', value: e.target.value }))}
          label="Nom"
          margin="normal"
        />
      </Grid>
      <Grid item>
        <FontAwesomeSelector
          value={form.icon}
          valueType={CLASS_TYPE}
          onChange={value => dispatch(updateForm({ prop: 'icon', value: value }))}
        />
      </Grid>
    </Grid>
  );
}
