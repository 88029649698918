import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import {closeDialog, saveHighlight} from './highlightSlice';
import HighlightForm from "./HighlightForm";
import CircularProgress from "@mui/material/CircularProgress";

export default function HighlightDialog() {
  const mode = useSelector(state => state.highlights.dialog.mode);
  const open = useSelector(state => state.highlights.dialog.open);
  const loading = useSelector(state => state.highlights.loading.form);
  const dispatch = useDispatch();
  const title = mode === 'add' ? 'Ajouter un point fort' : 'Modifier un point fort';

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(closeDialog())}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <HighlightForm/>
      </DialogContent>
      <DialogActions>
        <Button key='cancel' onClick={() => dispatch(closeDialog())} variant="contained" color="secondary">Annuler</Button>
        <Button key={mode} onClick={() => dispatch(saveHighlight())} disabled={loading} variant="contained" color="primary" startIcon={loading ? <CircularProgress size={14}/> : <CheckIcon/>}>
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
