import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from '@mui/material/Collapse';
import VersionFeatureForm from './VersionFeatureForm';
import {removeFeature} from '../versionSlice';
import './VersionFeatureCard.scss';

VersionFeatureCard.propTypes = {
  versionFeature: PropTypes.object
};

const styles = {
  expand: {
    transform: 'rotate(0deg)',
    transition: '500ms'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
};

export default function VersionFeatureCard(props) {
  const {versionFeature} = props;
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const iconExpandStyles = expanded ? {...styles.expand, ...styles.expandOpen} : styles.expand;

  const iconExpand = (
    <IconButton color="primary" sx={iconExpandStyles} onClick={() => setExpanded(!expanded)} aria-label="Ouvrir" aria-expanded={expanded}>
      <ExpandMoreIcon/>
    </IconButton>
  );

  return (
    <Card className="version-feature-card">
      <CardHeader title={versionFeature.feature.name} action={iconExpand}/>
      <Collapse in={expanded}>
        <CardContent>
          <VersionFeatureForm versionFeature={versionFeature}/>
        </CardContent>
        <CardActions>
          <Button onClick={() => dispatch(removeFeature(versionFeature))} variant="contained" color="error" startIcon={<DeleteIcon/>}>
            Supprimer
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
}
