import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableRow from '@mui/material/TableRow';
import { deleteVersion, moveVersion, openDialog, fetchVersion} from '../versionSlice';
import ConfirmDialog from "../../../../../components/ConfirmDialog";
import Price from '../../../../../components/Price';
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";

export default function VersionRow(props) {
  const {version} = props;
  const loading = useSelector(state => state.versions.loading.form);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const handleEditModal = e => {
    e.preventDefault();
    dispatch(fetchVersion(version.id));
    dispatch(openDialog('edit'));
  }

  const handleMove = (event, position) => {
    event.stopPropagation();
    dispatch(moveVersion({version, position: version.position + position}));
  }

  return (
    <TableRow key={version.id}>
      <TableCell scope="row">{version.name}</TableCell>
      <TableCell scope="row">
        <Price value={version.price}/>
      </TableCell>
      <TableCell>
        <IconButton onClick={e => handleMove(e, -1)} color="primary" aria-label="Monter">
          <ArrowUpward/>
        </IconButton>
        <IconButton onClick={e => handleMove(e, 1)} color="primary" aria-label="Descendre">
          <ArrowDownward/>
        </IconButton>
        <IconButton onClick={handleEditModal} color="primary" aria-label="Modifier">
          <EditIcon/>
        </IconButton>
        <IconButton onClick={() => setConfirmOpen(true)} disabled={loading.version === version.id} color="error" aria-label="Supprimer">
          <DeleteIcon/>
        </IconButton>
        <ConfirmDialog
          title={'Supprimer ' + version.name + ' ?'}
          confirmLabel='Supprimer'
          confirmColor='secondary'
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={() => dispatch(deleteVersion(version))}
        >
          <p>Êtes-vous sur de vouloir supprimer {version.name} ?</p>
        </ConfirmDialog>
      </TableCell>
    </TableRow>
  );
};
