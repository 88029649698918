import React from 'react';
import {useSelector} from 'react-redux';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import CategoryIcon from '@mui/icons-material/Category';
import FeatureTreeItem from './FeatureTreeItem';
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import EditBtnWithLocales from "../../../components/BtnEditWithLocales";

export default function CategoryTreeItem(props) {
  const { category, onDelete, onMove, onEdit, showFeatures } = props;
  const product = useSelector(state => state.product.item);

  const subCategories = category.children
    .slice()
    .sort((c1, c2) => c1.position > c2.position)
    .map(c => <CategoryTreeItem key={c['@id']} category={c} onDelete={onDelete} onMove={onMove} onEdit={onEdit} showFeatures={showFeatures}/>)
  const features = product && showFeatures ? product.features
      .filter(f => f.category === category.id || f.category.id === category.id) // Only show current category features
      .sort((f1, f2) => f1.position.toString().localeCompare(f2.position.toString(), 'en', {numeric: true}))
      .map(f => <FeatureTreeItem key={f['@id']} feature={f}/>)
    : [];

  const handleMove = (event, position) => {
    event.stopPropagation();
    onMove(category, category.position + position);
  }

  const moveBtns = typeof onMove !== 'undefined' ? (
    <>
      <IconButton onClick={e => handleMove(e, -1)} color="primary" aria-label="Monter">
        <ArrowUpward/>
      </IconButton>
      <IconButton onClick={e => handleMove(e, 1)} color="primary" aria-label="Descendre">
        <ArrowDownward/>
      </IconButton>
    </>
  ) : null;

  const handleDelete = e => {
    e.stopPropagation();
    onDelete(category);
  }

  const deleteBtn = typeof onDelete !== 'undefined' ? (
    <IconButton onClick={handleDelete} color="error" aria-label="Supprimer">
      <DeleteIcon/>
    </IconButton>
  ) : null;

  const editBtn = typeof onEdit !== 'undefined' ? (
    <EditBtnWithLocales onClick={() => onEdit(category)}/>
  ) : null;

  const label = (
    <div>
      <CategoryIcon/>
      {category.name}
      {moveBtns}
      {deleteBtn}
      {editBtn}
    </div>
  );

  return (
    <TreeItem itemId={category['@id']} label={label}>
      {subCategories}
      {features}
    </TreeItem>
  );
}
