import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import VersionFeatureCard from './VersionFeatureCard';
import {setSelectedFeature, addFeature, updateFilters} from '../versionSlice';
import './VersionFeatureList.scss';

export default function VersionFeatureList() {
  const form = useSelector(state => state.versions.form);
  const product = useSelector(state => state.product.item);
  const version = useSelector(state => state.versions.form);
  const selectedFeature = useSelector(state => state.versions.selectedFeature);
  const nameFilter = useSelector(state => state.versions.filters.features.name);
  const dispatch = useDispatch();

  const selectFeatures = product.features
    .filter(f => typeof version.features.find(vf => vf.feature.id === f.id) === 'undefined')
    .sort((f1, f2) => f1.name.localeCompare(f2.name))
    .map(f => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)
  ;
  console.log(form.features)
  const features = form.features
    .filter(vf => vf.feature.name.toLowerCase().includes(nameFilter.toLowerCase()))
    .map(vf => (
    <Grid key={vf.id} item>
      <VersionFeatureCard versionFeature={vf}/>
    </Grid>
  )).reverse();

  return (
    <div className="version-feature-list">
      <Grid container direction="row" className="form-container">
        <Select
          value={selectedFeature ? selectedFeature.id : ''}
          onChange={e => dispatch(setSelectedFeature(product.features.find(f => f.id === e.target.value)))}
        >
          {selectFeatures}
        </Select>
        <Button onClick={() => dispatch(addFeature())} startIcon={<AddIcon/>} disabled={selectedFeature === null} variant="contained" color="primary">
          Ajouter
        </Button>
      </Grid>
      <fieldset className="mb-1">
        <legend>Filtres</legend>
        <Input type="text" value={nameFilter} onChange={e => dispatch(updateFilters({ entity: 'features', name: 'name', value: e.target.value }))} placeholder="Rechercher"/>
      </fieldset>
      <Grid container direction="column" spacing={2}>
        {features}
      </Grid>
    </div>
  );
}
