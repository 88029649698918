import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CategoryIcon from '@mui/icons-material/Category';
import StarsIcon from '@mui/icons-material/Stars';
import GroupIcon from '@mui/icons-material/Group';
import ListItemNavLink from './components/ListItemNavLink';
import SignIn from './SignIn';
import ProductList from './features/product/list/ProductList';
import ProductItem from './features/product/item/ProductItem';
import CategoryTreeView from './features/category/list/CategoryTreeView';
import HighlightList from './features/highlight/list/HighlightList';
import UserList from './features/user/list/UserList';
import Notifier from './components/Notifier';
import AlertDialog from './features/alert/AlertDialog';
import {currentUser, fetchLocales, fetchDefaultLocale} from './appSlice';
import {fetchProducts} from './features/product/list/productListSlice';
import {fetchCategories} from './features/category/categorySlice';
import {fetchHighlights} from './features/highlight/highlightSlice';
import './App.scss';

library.add(fas, fab, far);
dom.watch()

const drawerWidth = 240;

const styles = {
  root: {
    display: 'flex',
  },
  drawer: theme => ({
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  }),
  appBar: theme => ({
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  }),
  menuButton: theme => ({
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }),
  // necessary for content to be below app bar
  toolbar: theme => theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme => theme.spacing(3),
  },
};

function App() {
  const user = useSelector(currentUser);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchLocales());
    dispatch(fetchDefaultLocale());
    dispatch(fetchProducts());
    dispatch(fetchCategories());
    dispatch(fetchHighlights());
  }, [dispatch]);

  // Check if user is connected
  if (user === null) {
    return <SignIn/>;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Box sx={styles.toolbar} />
      <Divider />
      <List>
        <ListItemNavLink icon={<DashboardIcon/>} primary='Dashboard' to='/' exact/>
        <ListItemNavLink icon={<DirectionsBoatIcon/>} primary='Bateaux' to='/products'/>
        <ListItemNavLink icon={<CategoryIcon/>} primary='Catégories' to='/categories'/>
        <ListItemNavLink icon={<StarsIcon/>} primary='Points forts' to='/highlights'/>
        <ListItemNavLink icon={<GroupIcon/>} primary='Utilisateurs' to='/users'/>
      </List>
    </div>
  );

  return (
    <>
      <Box sx={styles.root}>
        <CssBaseline />
        <AppBar position="fixed" sx={styles.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={styles.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Administration
            </Typography>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={styles.drawer} aria-label="mailbox folders">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{display: {xs: 'block', sm: 'none'}}}
            PaperProps={{sx: styles.drawerPaper}}
            ModalProps={{keepMounted: true}}
          >
            {drawer}
          </Drawer>
          <Drawer
            sx={{display: {xs: 'none', sm: 'block'}}}
            PaperProps={{sx: styles.drawerPaper}}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={styles.content}>
          <Box sx={styles.toolbar}/>

          <Routes>
            <Route exact path="/"/>
            <Route exact path="/products" element={<ProductList/>}/>
            <Route path="/products/:slug" element={<ProductItem/>}/>
            <Route exact path="/categories" element={<CategoryTreeView/>}/>
            <Route exact path="/highlights" element={<HighlightList/>}/>
            <Route exact path="/users" element={<UserList/>}/>
          </Routes>
        </Box>
      </Box>
      <Notifier/>
      <AlertDialog/>
    </>
  );
}

export default App;
