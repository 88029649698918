import React  from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import HighlightRow from './HighlightRow';
import HighlightDialog from '../HighlightDialog';
import {openDialog} from "../highlightSlice";

export default function HighlightList() {
  const highlights = useSelector(state => state.highlights.items);
  const loading = useSelector(state => state.highlights.loading);
  const dispatch = useDispatch();

  if (loading.list) {
    return <CircularProgress/>;
  }

  const rows = highlights.map(h => <HighlightRow key={h.id} highlight={h}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} variant="contained" color="primary">Ajouter un point fort</Button>
      <hr/>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Icone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <HighlightDialog/>
    </>
  );
}
