import React  from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from 'react-redux';
import { updateNewProduct, addProduct, removeProduct } from './productListSlice';
import { openModal, closeModal } from '../../alert/alertDialogSlice';

const styles = {
  table: {
    minWidth: 650,
  },
};

export default function ProductList() {
  const newProduct = useSelector(state => state.products.newProduct);
  const products = useSelector(state => state.products.items);
  const loading = useSelector(state => state.products.loading);
  const dispatch = useDispatch();

  if (loading.list) {
    return <CircularProgress/>;
  }

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(addProduct());
  };

  const handleDeleteButton = (e, product) => {
    e.preventDefault();
    dispatch(openModal({
      title: `Supprimer ${product.name} ?`,
      content: `Êtes-vous sûr de vouloir supprimer ${product.name} ?`,
      buttons: [
        <Button key='delete' onClick={() => dispatch(removeProduct(product.slug))} color="error">Supprimer</Button>,
        <Button key='cancel' onClick={() => dispatch(closeModal())} color="primary">Annuler</Button>
      ]
    }));
  };

  const rows = products.map(p => (
    <TableRow key={p.id}>
      <TableCell scope="row">{p.name}</TableCell>
      <TableCell scope="row">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(p.price)}</TableCell>
      <TableCell>
        <Link to={"/products/" + p.slug}>
          <IconButton color="primary" aria-label="Modifier">
            <EditIcon/>
          </IconButton>
        </Link>
        <IconButton onClick={e => handleDeleteButton(e, p)} disabled={loading.product === p.id} color="error" aria-label="Supprimer">
          <DeleteIcon/>
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid container alignItems="center" spacing={5}>
          <Grid item>
            <TextField
              value={newProduct.name}
              onChange={e => dispatch(updateNewProduct({ prop: 'name', value: e.target.value }))}
              label="Nom"
              margin="normal"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              value={newProduct.price}
              onChange={e => dispatch(updateNewProduct({ prop: 'price', value: e.target.value }))}
              type="number"
              label="Prix"
              margin="normal"
              required
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
            />
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary" disabled={loading.form} startIcon={loading.form ? <CircularProgress size={14}/> : <AddIcon/>}>
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer component={Paper}>
        <Table sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
