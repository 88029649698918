import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import UserRow from './UserRow';
import UserDialog from '../UserDialog';
import {fetchUsers, openDialog} from '../userSlice';

const styles = {
  table: {
    minWidth: 650,
  },
};

export default function UserList() {
  const users = useSelector(state => state.users.items);
  const loading = useSelector(state => state.users.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  if (loading.list) {
    return <CircularProgress/>;
  }

  const rows = users.map(u => <UserRow key={u.id} user={u}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} variant="contained" color="primary">Ajouter un utilisateur</Button>
      <hr/>
      <TableContainer component={Paper}>
        <Table sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <UserDialog/>
    </>
  );
}
