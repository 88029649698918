import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { saveToken } from './app/services/jwt.service';
import ApiService from './app/services/api.service';

export const fetchLocales = createAsyncThunk(
  'locales/fetch',
  async () => await ApiService.get('locales')
);

export const fetchDefaultLocale = createAsyncThunk(
  'defaultLocale/fetch',
  async () => await ApiService.get('default-locale')
);

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    user: null,
    token: null,
    notifications: [],
    locales: [],
    defaultLocale: null,
    locale: null
  },
  reducers: {
    signInSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      saveToken(action.payload.token);
      ApiService.setHeader();
    },
    enqueueSnackbar: (state, action) => {
      action.payload.key = new Date().getTime() + Math.random();
      state.notifications.push(action.payload);
    },
    removeSnackbar: (state, action) => {
      state.notifications = state.notifications.filter(n => n.options.key === action.payload);
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocales.fulfilled, (state, action) => {
        state.locales = action.payload;
      })
      .addCase(fetchDefaultLocale.fulfilled, (state, action) => {
        state.defaultLocale = action.payload;
      });
  }
});

export const { signInSuccess, enqueueSnackbar, removeSnackbar, setLocale } = appSlice.actions;

export const currentUser = state => state.app.user;

export default appSlice.reducer;
